@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-pause {
    animation-play-state: paused;
  }
}

@layer base {
  body {
    font-family: "Inter";
  }

  .basier {
    font-family: BasierCircle;
  }

  .gradient-text {
    background: linear-gradient(91.92deg, #5589fb 5.15%, #2148d1 88.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .gradient-bg-diffuse-blue {
    background: linear-gradient(96.51deg, #5589fb 9.34%, #7285ee 90.8%);
  }

  .gradient-dark-header-dropdown {
    background: linear-gradient(110.93deg, #2f2f2f 0.95%, #2b2358 91.67%);
  }

  .gradient-page-base {
    background: linear-gradient(180deg, #3d3c3c 0%, #161c28 100%);
  }

  .gradient-page-lower {
    background: linear-gradient(180deg, #161c28 19.73%, #1f2a40 64.37%);
  }

  .gradient-biologists-page {
    background: linear-gradient(
      180deg,
      rgba(38, 41, 48, 0.17) 6.73%,
      rgba(91, 15, 187, 0.17) 72.49%,
      rgba(38, 41, 48, 0.17) 97%,
      #161c28 100%
    );
  }

  .gradient-bioinformatics-page {
    background: linear-gradient(
      180deg,
      #3d3c3c 2.91%,
      #4e314b 54.45%,
      #161c28 101.3%
    );
  }

  .gradient-institutes-page {
    background: linear-gradient(
      180deg,
      #3d3c3c 0%,
      #163b2e 51.04%,
      #161c28 96.35%
    );
  }

  .gradient-workflows-page {
    background: linear-gradient(
      180deg,
      #3d3c3c 5.23%,
      #1e4053 50.91%,
      #161c28 91.46%
    );
  }

  .gradient-data-page {
    background: linear-gradient(
      180deg,
      #3d3c3c 6.57%,
      #0c1542 50.93%,
      #161c28 90.31%
    );
  }

  .gradient-industry-page {
    background: linear-gradient(
      180deg,
      #f5f4f4 0%,
      #e6e3fb 48.44%,
      #fdfdfd 100%
    );
  }
  .gradient-about-page {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(234, 218, 241, 0.32) 23.44%,
      rgba(210, 221, 232, 0.32) 70.31%,
      #ffffff 100%
    );
  }
}

/* @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: local("Inter"), url("fonts/Inter-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: local("Inter"), url("fonts/Inter-Medium.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: local("Inter"), url("fonts/Inter-SemiBold.ttf") format("opentype");
} */

@font-face {
  font-family: "BasierCircle";
  font-style: normal;
  font-weight: 400;
  src: local("BasierCircle"),
    url("fonts/BasierCircle-Regular.woff2") format("woff2"),
    url("fonts/BasierCircle-Regular.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "BasierCircle";
  font-style: normal;
  font-weight: 600;
  src: local("BasierCircle"),
    url("fonts/BasierCircle-SemiBold.woff2") format("woff2"),
    url("fonts/BasierCircle-SemiBold.ttf") format("opentype");

  font-display: swap;
}
